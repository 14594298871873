import React from 'react';
import "./Privacy_Content.scss";

const PrivacyPolicy = () => {
  return (
    <div className='privacy'>
      <div className="privacy-notes">
        <h1 className="title">Privacy Policy</h1>
        <p className="intro">
          At Pavanputra LLC, we are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data when you use our Chatbot accessible via WhatsApp.
        </p>
        <h2 className="section-title">1. Information Collection</h2>
        <p className="section-content">
          We collect information you provide while interacting with our Chatbot. This includes personal details, fitness-related queries, and other information shared during conversations. Additionally, we may collect your phone number for sign-in purposes and fitness progress data if you opt-in for this feature.
        </p>
        <h2 className="section-title">2. Usage of Information</h2>
        <p className="section-content">
          The information collected is used to improve our Chatbot's responses and provide personalized fitness-related insights. Your data is securely stored and analyzed to enhance user experience. If you opt-in to track your fitness progress, the data will be stored for your convenience.
        </p>
        <h2 className="section-title">3. Account Deletion</h2>
        <p className="section-content">
          You can delete your account and associated data directly through the application. Once the account deletion request is made, your data, including your phone number and fitness progress, will be permanently deleted within 30 days. To delete your account, navigate to the "Settings" section in the app and follow the prompts for account deletion.
        </p>
        <h2 className="section-title">4. Information Sharing</h2>
        <p className="section-content">
          We do not share your personal information with third parties for marketing purposes. However, we may share aggregated and anonymized data for research and analytics purposes. Any data shared is stripped of personal identifiers.
        </p>
        <h2 className="section-title">5. Security</h2>
        <p className="section-content">
          We implement technical and organizational measures to protect your data from unauthorized access or disclosure. Your privacy and data security are of utmost importance to us.
        </p>
        <h2 className="section-title">6. Changes to Policy</h2>
        <p className="section-content">
          Pavanputra LLC reserves the right to update this Privacy Policy. Any changes will be posted on this page. Continued use of our Chatbot after changes implies your acceptance of the revised policy.
        </p>
        <h2 className="section-title">7. Contact Us</h2>
        <p className="section-content">
          If you have any questions or concerns regarding this Privacy Policy, please contact us at:
        </p>
        <p className="section-content">
          <strong>Email:</strong> info@pavan-putra.com
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
